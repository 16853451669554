<template>
    <div v-if="loaded" class="question hybrid column">
        <div class="hybrid-days-container">
            <div class="input-with-label">
                <p class="label">{{$t('workProviderOffer.shared.card.hybrid.days.regular')}}</p>
                <div class="input-group">
                    <the-mask :class="['input input-text', { 'read-only': readonly }, error()]" mask="N" :tokens="maskToken" v-model.number="data.hybrid.days.number" />
                    
                    <p>{{$t('language.words.by')}}</p>

                    <div class="button-group collapsed">
                        <button id="hybrid-weekly-choice" :class="['cta cta-option', { 'read-only': readonly }, { active: data.hybrid.days.period === 'weekly' }]" @click="data.hybrid.days.period = 'weekly'">{{$t('workProviderOffer.shared.card.hybrid.days.periods.weekly.capitalize')}}</button>
                        <button id="hybrid-biweekly-choice" :class="['cta cta-option middle', { 'read-only': readonly }, { active: data.hybrid.days.period === 'biweekly' }]" @click="data.hybrid.days.period = 'biweekly'">{{$t('workProviderOffer.shared.card.hybrid.days.periods.biweekly.capitalize')}}</button>
                        <button id="hybrid-monthly-choice" :class="['cta cta-option', { 'read-only': readonly }, { active: data.hybrid.days.period === 'monthly' }]" @click="data.hybrid.days.period = 'monthly'">{{$t('workProviderOffer.shared.card.hybrid.days.periods.monthly.capitalize')}}</button>
                    </div>
                </div>
            </div>
        </div>
        
        <FormError v-if="!readonly" :data="validation.hybrid.days.number" />
    </div>
</template>

<script>
import FormError from '../../components/Utils/FormError.vue'

export default {
    name: 'HybridComponent',

    components: { FormError },

    props: {
        hybrid: {
            type: Object,
            default() {
                return {}
            }
        },

        readonly: {
            type: Boolean,
            default: false
        },

        validation: {
            type: Object,
            default() {
                return {}
            }
        },
    },

    watch: {
        hybrid: function(val) {
            this.data.hybrid = val;
        },

        data: {
            deep: true,
            handler() {
                const self = this;

                if(!self.readonly) {
                    if(self.data.hybrid.days.number !== 0 && self.data.hybrid.days.number !== '') {
                        if(self.validation.hybrid.days.number.error === true) { self.validation.hybrid.days.number.error = false; }
                    }

                    self.$emit('update', self.data)
                }
            }
        }
    },

    data() {
        return {
            data: {
                hybrid: {},
            },

            maskToken: {
                N: {
                    pattern: /[1-7]/
                }
            },

            loaded: false
        }
    },

    methods: {
        error: function() {
            try {
                if(!this.readonly) return { error: this.validation.hybrid.days.number.error }
            } catch (e) {
                console.log(`${this.$options.name}: validation error`)
            }
        },
    },

    mounted() {
        const self = this;
        
        if(self.hybrid) {
            self.data = {
                hybrid: self.hybrid,
            };

            self.$nextTick(() => {
                self.loaded = true;
            })
        }
    }
}
</script>

