<template>
    <div>
        <div v-if="loaded">
            <div class="card card-split animate__animated animate__fadeIn">
                <div class="card-heading">
                    <p class="title">{{$t('workProviderOffer.shared.card.split.schedule.title')}}</p>
                </div>

                <div class="card-body">
                    <div class="question-wrapper">
                        <WorkDaysComponent  v-if="data.workDays"
                                            :workDays="data.workDays"
                                            :workTimes="data.workTimes"
                                            :validation="validation"
                                            client="worker"
                                            @update="update($event)" />
                        
                        <WorkTimesComponent  v-if="data.workDays"
                                            :workDays="data.workDays"
                                            :workTimes="data.workTimes"
                                            :hoursPerWeek="data.hoursPerWeek"
                                            :validation="validation"
                                            client="worker"
                                            @update="update($event)" />
                    </div>
                </div>
            </div>

            <div class="card card-split">
                <div class="card-heading">
                    <p>{{$t('workProviderOffer.shared.card.split.location.title')}}</p>
                </div>

                <div class="card-body">
                    <div class="question-wrapper">
                        <div class="question work-location disabled column">
                            <div class="label-container">
                                <p>{{$t('workLocationTitle')}}</p>
                            </div>

                            <div class="checkbox-container">
                                <div class="checkbox-with-label read-only reduced-opacity">
                                    <input type="checkbox" :checked="data.workLocation === 'regular' && !data.hybrid.hybrid">
                                    <p class="label">{{$t('regular')}}</p>
                                </div>
                                <div class="checkbox-with-label read-only reduced-opacity">
                                    <input type="checkbox" :checked="data.workLocation === 'remote' && !data.hybrid.hybrid">
                                    <p class="label">{{$t('remote')}}</p>
                                </div>
                                <div class="checkbox-with-label read-only reduced-opacity">
                                    <input type="checkbox" :checked="data.workLocation === 'regular' && data.hybrid.hybrid">
                                    <p class="label">{{$t('hybride')}}</p>
                                </div>
                                <div class="checkbox-with-label read-only reduced-opacity">
                                    <input type="checkbox" :checked="data.workLocation === 'both'">
                                    <p class="label">{{$t('both')}}</p>
                                </div>
                            </div>

                            <FormError :data="{ error: false, warning: true, key: 'workProviderOffer.shared.card.workLocation.alternate.over' }" />
                        </div>

                        <HybridComponent  v-if="data.hybrid.hybrid"
                                            :hybrid="data.hybrid"
                                            :validation="validation"
                                            @update="update($event)" />
                    </div>
                </div>
            </div>

            <div class="card card-split">
                <div class="card-heading with-subtitle">
                    <p class="title">{{$t('workerOffer.card.split.classification.title')}}</p>
                    <p class="subtitle">{{$t('workProviderOffer.shared.card.split.classification.subtitle')}}</p>
                </div>

                <div class="card-body">
                    <div class="question-wrapper">
                        <div class="question choose-classification column">
                            <div class="label-container">
                                <p class="title">{{$t('employmentSituations.title')}}</p>
                            </div>

                            <multiselect    v-model="chooseClassification" 
                                            :options="chooseClassificationOptions"
                                            :close-on-select="true"
                                            :multiple="false"
                                            :class="error('chooseClassification')"

                                            :placeholder="$t('employmentSituations.placeholder')"
                                            :select-label="$t('language.multiselect.label.select')"
                                            :selected-label="$t('language.multiselect.label.selected')"
                                            :deselect-label="$t('language.multiselect.label.deselect')"
                                            :custom-label="translate_choose_classification"> 
                    
                                            <template slot="noResult">{{$t('language.multiselect.label.no_result')}}</template>
                                            <template slot="noOptions">{{$t('language.multiselect.label.no_options')}}</template>
                            </multiselect>
                            
                            <FormError :data="validation.chooseClassification" />
                        </div>

                        <ClassificationComponent    v-if="chooseClassification && data.classification.all === false"
                                                    :classification="data.classification"
                                                    :validation="validation"
                                                    @update="update($event)" />
                    </div>
                </div>
            </div>

            <div class="card card-split">
                <div class="card-heading">
                    <p class="title">{{$t('workProviderOffer.shared.card.split.compensation.title')}}</p>
                </div>

                <div class="card-body">
                    <div class="question-wrapper">
                        <div class="question salary">
                            <div class="label-container">
                                <p>{{$t('workerOffer.card.create.salary.label')}}</p>
                                <FormError :data="validation.salary" />
                            </div>
                            
                            <div class="input-button-container">
                                <the-mask v-if="data.salary.type === 'hourly'" :class="['input input-text', error('salary')]" :mask="['## $', '##,## $', '### $', '###,### $']" v-model="data.salary.amount" />
                                <the-mask v-else :class="['input input-text', error('salary')]" :mask="['## $', '##,## $', '### $', '#### $', '##,### $', '###,### $']" v-model="data.salary.amount" />

                                <div class="button-group collapsed">
                                    <button :class="['cta cta-option', {active: data.salary.type === 'yearly'}]" @click="data.salary.type = 'yearly'">{{$t('workerOffer.card.create.salary.types.yearly')}}</button>
                                    <button :class="['cta cta-option', {active: data.salary.type === 'hourly'}]" @click="data.salary.type = 'hourly'">{{$t('workerOffer.card.create.salary.types.hourly')}}</button>
                                </div>
                            </div>                
                        </div>
                    </div>

                    <div v-if="age_group === 'below_55' || age_group === '55_and_above' && !retirement_amount" class="trigger-cta-container preferences">
                        <button v-if="age_group === 'below_55' || age_group === '55_and_above' && !retirement_amount" class="cta cta-secondary cta-slim" @click="recommendations.salary.modal = true">{{$t('workerOffer.card.create.salary.suggest')}}</button>
                        <button v-if="age_group === '55_and_above' && !retirement_amount" class="cta cta-outline dark cta-slim" @click="$utils._navigate_to_name('retirement-calculator-embedded')">{{$t('retirement_calculator.onboarding.modal.trigger')}}</button>
                    </div>

                    <div v-if="retirement_amount && age_group === '55_and_above'" class="retirement-maximise-salary">
                        <p class="title">{{$t('retirement_calculator.get-started.maximise.title')}}</p>
                        
                        <div v-if="data.salary.type === 'hourly' && hourly_salary_to_yearly !== 0" class="retirement_amount salary-yearly">
                            <p>{{$t('retirement_calculator.get-started.maximise.yearly_salary')}}</p>
                            <span class="amount">{{$utils._format_yearly_cash(hourly_salary_to_yearly)}}</span>
                        </div>
                        
                        <div class="retirement_amount">
                            <p>{{$t('retirement_calculator.get-started.maximise.retirement_amount')}}</p>
                            <span class="amount">{{$utils._format_yearly_cash(retirement_amount)}}</span>
                        </div>

                        <div v-if="data.salary.type === 'yearly' && data.salary.amount > retirement_amount">
                            <span class="attention" v-html="$t('retirement_calculator.get-started.maximise.errors.annual', { entered_amount: $utils._format_yearly_cash(data.salary.amount), retirement_amount: $utils._format_yearly_cash(retirement_amount) })" />
                        </div>

                        <div v-if="data.salary.type === 'hourly' && hourly_salary_to_yearly > retirement_amount">
                            <span class="attention" v-html="$t('retirement_calculator.get-started.maximise.errors.hourly', { entered_amount: formatted_hourly_salary(data.salary.amount, true), hours: data.hoursPerWeek, annual: $utils._format_yearly_cash(hourly_salary_to_yearly), retirement_amount: $utils._format_yearly_cash(retirement_amount) })" />
                        </div>

                        <div class="trigger-cta-container preferences">
                            <button class="cta cta-secondary cta-slim" @click="recommendations.salary.modal = true">{{$t('workerOffer.card.create.salary.suggest')}}</button>
                            <button class="cta cta-outline dark cta-slim" @click="$utils._navigate_to_name('retirement-calculator-embedded')">{{retirement_amount ? $t('retirement_calculator.onboarding.modal.retrigger') : $t('retirement_calculator.onboarding.modal.trigger')}}</button>
                        </div> 
                    </div>
                </div>
            </div>

            <div class="card card-split benefits">
                <div class="card-heading">
                    <p class="title">{{$t('workerOffer.card.create.benefits.title')}}</p>
                </div>

                <div class="card-body">
                    <div class="question-wrapper">
                        <BenefitsComponent  :benefits="data.benefits" />
                    </div>

                    <div class="trigger-cta-container benefits">
                        <button class="cta cta-outline dark cta-slim" @click="recommendations.benefits.modal = true">{{$t('workerOffer.card.create.benefits.suggest')}}</button>
                        <button class="cta cta-primary cta-slim mobile responsive" :class="{ loading: loading }" @click="updateOffer()">{{$t('cards.generic.submit.label')}}</button>
                    </div>
                </div>
            </div>

            <div class="card-split-footer desktop responsive">
                <button class="cta cta-primary cta-slim" :class="{ loading: loading }" @click="updateOffer()">{{$t('cards.generic.submit.label')}}</button>
            </div>

            <RecommendationSalaryModal :show="recommendations.salary.modal" :classification="data.classification" @cancel="recommendations.salary.modal = false" />
            <RecommendationBenefitsModal :show="recommendations.benefits.modal" :classification="data.classification" @cancel="recommendations.benefits.modal = false" />
        </div>

        <div v-else class="card loading"></div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import FormError from '../../../../components/Utils/FormError.vue';

import WorkDaysComponent from '../../../Form/WorkDaysComponent.vue';
import WorkTimesComponent from '../../../Form/WorkTimesComponent.vue';
import HybridComponent from '../../../Form/HybridComponent.vue';
import BenefitsComponent from '../../../Form/BenefitsComponent.vue';
import ClassificationComponent from '../../../Form/ClassificationComponent.vue';
import RecommendationSalaryModal from '../../../Modals/GetStarted/RecommendationSalaryModal.vue';
import RecommendationBenefitsModal from '../../../Modals/GetStarted/RecommendationBenefitsModal.vue';

export default {
    name: 'WorkerPreferencesCard',

    components: {
        FormError,
        WorkDaysComponent,
        WorkTimesComponent,
        HybridComponent,
        BenefitsComponent,
        ClassificationComponent,
        RecommendationSalaryModal,
        RecommendationBenefitsModal
    },

    props: {
        retirement_amount: null,
    },

    data() {
        return {
            data: {},

            workLocationOptions: ['remote', 'regular'],

            sectorOptions: [],
            classOptions: [],
            jobOptions: [],

            formValidated: false,
            loading: false,
            loaded: false,

            chooseClassification: null,
            chooseClassificationOptions: ['choose', 'no'],

            recommendations: {
                salary: {
                    modal: false
                },

                benefits: {
                    modal: false
                }
            },
            
            validation: {
                classification: {
                    interests: {
                        error: false,
                        key: 'errors.validation.fieldMandatory'
                    },

                    experienceLevel: {
                        error: false,
                        key: 'errors.validation.fieldMandatory'
                    }
                },

                chooseClassification: {
                    error: false,
                    key: 'errors.validation.fieldMandatory'
                },
                
                workDays: {
                    error: false,
                    key: 'errors.validation.fieldMandatory'
                },

                hoursPerWeek: {
                    error: false,
                    key: 'errors.validation.fieldMandatory'
                },

                workTimes: {
                    monday: {
                        start: {
                            error: false,
                            key: 'errors.validation.timeFormatInvalid.start'
                        },

                        end: {
                            error: false,
                            key: 'errors.validation.timeFormatInvalid.end'
                        }
                    },

                    tuesday: {
                        start: {
                            error: false,
                            key: 'errors.validation.timeFormatInvalid.start'
                        },

                        end: {
                            error: false,
                            key: 'errors.validation.timeFormatInvalid.end'
                        }
                    },

                    wednesday: {
                        start: {
                            error: false,
                            key: 'errors.validation.timeFormatInvalid.start'
                        },

                        end: {
                            error: false,
                            key: 'errors.validation.timeFormatInvalid.end'
                        }
                    },

                    thursday: {
                        start: {
                            error: false,
                            key: 'errors.validation.timeFormatInvalid.start'
                        },

                        end: {
                            error: false,
                            key: 'errors.validation.timeFormatInvalid.end'
                        }
                    },

                    friday: {
                        start: {
                            error: false,
                            key: 'errors.validation.timeFormatInvalid.start'
                        },

                        end: {
                            error: false,
                            key: 'errors.validation.timeFormatInvalid.end'
                        }
                    },

                    saturday: {
                        start: {
                            error: false,
                            key: 'errors.validation.timeFormatInvalid.start'
                        },

                        end: {
                            error: false,
                            key: 'errors.validation.timeFormatInvalid.end'
                        }
                    },

                    sunday: {
                        start: {
                            error: false,
                            key: 'errors.validation.timeFormatInvalid.start'
                        },

                        end: {
                            error: false,
                            key: 'errors.validation.timeFormatInvalid.end'
                        }
                    }
                },

                hybrid: {
                    days: {
                        number: {
                            error: false,
                            key: 'errors.validation.fieldMandatory'
                        }
                    }
                },

                workLocation: {
                    error: false,
                    key: 'errors.validation.fieldMandatory'
                },

                salary: {
                    error: false,
                    key: 'errors.validation.fieldMandatory'
                }
            }
        }
    },

    computed: {
        ...mapGetters([
            'age_group'
        ]),

        hourly_salary_to_yearly: function() {
            const self = this;
            
            if(self.data.hoursPerWeek !== '' && self.data.hoursPerWeek > 0) {
                const formatted_salary = self.formatted_hourly_salary(self.data.salary.amount, false);
                return (self.data.hoursPerWeek * formatted_salary) * 50;
            }

            return 0;
        }
    },

    watch: {
        chooseClassification: function(choice) {
            const self = this;
            
            if(self.loaded) {
                self.data.classification.all = choice === 'no';
                self.data.classification.interests = [];
                self.data.classification.query = '';
                self.data.classification.experienceLevel = 'none';
            }
        },

        'data.workTimes':  {
            deep: true,
            handler() {
                const self = this;
                if(self.data.workTimes.length > 0) {
                    if(!isNaN(self.$utils._calculate_hours_per_week_from_work_times(self.data.workTimes))) {
                        self.data.calculated_hours_per_week = self.$utils._calculate_hours_per_week_from_work_times(self.data.workTimes);
                    }else {
                        self.data.calculated_hours_per_week = 0;
                    }
                }else {
                    self.data.calculated_hours_per_week = 0;
                }
            }
        },

        'recommendations.salary.modal': function() {
            const self = this;
            if(self.recommendations.salary.modal) { self.$utils._open_modal(); }
            else { self.$utils._close_modal(); }
        },

        'recommendations.benefits.modal': function() {
            const self = this;
            if(self.recommendations.benefits.modal) { self.$utils._open_modal(); }
            else { self.$utils._close_modal(); }
        }
    },

    methods: {
        validate_mandatory_fields: function() {
            const self = this;
            self.resetValidation();

            ['workLocation'].forEach(key => {
                if(!self.data[key] || self.data[key] === '')
                    self.validation[key].error = true;
            });
            
            ['workDays'].forEach(key => {
                if(!self.data[key] || self.data[key].length === 0) {
                    self.validation[key].error = true;
                }
            });

            if(self.data.classification.all === false) {
                if(self.data.classification.interests.length === 0) {
                    self.validation.classification.interests.error = true;
                        self.formValidated = false;
                }
            }

            if(!self.chooseClassification) {
                self.validation.chooseClassification.error = true;
                self.formValidated = false;
            }

            if(parseFloat(self.data.hoursPerWeek) === 0) {
                self.validation['hoursPerWeek'].error = true;
                self.validation['hoursPerWeek'].key = 'errors.validation.hours_per_week_too_high';
            }

            if(parseFloat(self.data.hoursPerWeek) > self.data.calculated_hours_per_week) {
                self.validation['hoursPerWeek'].error = true;
                self.validation['hoursPerWeek'].key = 'errors.validation.hours_per_week_too_high';
            }

            ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'].forEach(day => {
                if(self.data.workDays.includes(day)) {
                    const slots = ['start', 'end']
                    const period = self.data.workTimes.find(time => time.day === day)
                    slots.forEach(slot => {
                        if(period.timeSlots[0][slot].value === '' || !self.$utils._validate_time_slot(period.timeSlots[0][slot].value)) {
                            self.validation.workTimes[day][slot].error = true
                            self.validation.workTimes[day][slot].key = `validation.timeFormatInvalid.${slot}`
                        }
                    })
                }
            })

            if(self.data.hybrid.hybrid) {
                if(!self.data.hybrid.days.number) {
                    self.validation.hybrid.days.number.error = true;
                    self.formValidated = false
                }
            }

            if(self.data.salary.amount === '0' || self.data.salary.amount === '') {
                self.validation.salary.error = true;
            }

            if(self.data.salary.type && self.data.salary.amount && self.data.salary.type === 'hourly' && !self.$utils._validate_hourly_salary(self.data.salary.amount)) {
                self.validation.salary.error = true;
                self.validation.salary.key = 'errors.validation.salaryTooLow';
            }

            const days = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']
            Object.keys(self.validation).forEach(val => {
                if(self.validation[val].error)
                    self.formValidated = false

                if(val === 'workTimes') {
                    days.forEach(day => {
                        if(self.validation[val][day]['start'].error || self.validation[val][day]['end'].error)
                            self.formValidated = false
                    })
                }
            });
        },

        formatted_hourly_salary: function(amount, replace) {
            if(amount && !amount.includes('.') && (amount.length === 4 || amount.length === 5)) {
                if(replace) { return `${amount.slice(0, amount.length - 2)},${amount.slice(amount.length - 2)} $` }
                return `${amount.slice(0, amount.length - 2)}.${amount.slice(amount.length - 2)}`
            }

            return amount;
        },

        resetValidation: function() {
            const self = this;

            self.formValidated = true;

            Object.keys(self.validation).forEach(val => {
                if(val === 'workTimes') {
                    const days = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']
                    days.forEach(day => {
                        self.validation.workTimes[day].start.error = false;
                        self.validation.workTimes[day].end.error = false;
                    })
                } else if (val === 'classification') {
                    ['interests'].forEach(key => {
                        self.validation.classification[key].error = false;
                    })
                } else {
                    self.validation[val].error = false
                }
            });

            self.validation.hybrid.days.number.error = false;
            self.validation.chooseClassification.error = false;
            self.validation.hoursPerWeek.key = 'errors.validation.fieldMandatory';
            self.validation.salary.key = 'errors.validation.fieldMandatory';
        },

        updateOffer: function() {
            const self = this;

            self.validate_mandatory_fields()
            if(self.formValidated) {
                self.loading = true;

                const data = Object.assign({}, self.data);
                data.hoursPerWeek = parseFloat(data.hoursPerWeek);

                self.$axios
                    .put('/worker/offer', data)
                    .then(response => {
                        if(response.status == 200) {
                            self.$toasted.success(
                                self.$t('notifications.workProviderProfileUpdateSuccess'),
                                { icon: 'circle-check' }
                            );

                            self.$nextTick(() => {
                                self.loading = false;
                                self.formValidated = false;
                            })
                        }
                    })
                    .catch(error => {
                        if(error && error.response) {
                            if(error.response.status !== 400) {
                                console.log(JSON.stringify(error.response, null, 4))
                            }
                        } else { console.log(error); }

                        self.loading = false;
                    });
            } else {
                self.$toasted.error(
                    self.$t('notifications.allFieldsAreMandatory'),
                    { icon: 'circle-exclamation' }
                );
            }
        },

        getWorkerPreferences: function() {
            const self = this;

            self.$axios
                .get(`/worker/offer`)
                .then(response => {
                    if(response.status == 200) {
                        self.data = response.data;
                        
                        self.$utils._initializeWorkTimes(self.data.workTimes);
                        self.$utils._re_order_work_times(self.data.workTimes);
                        self.chooseClassification = self.data.classification.all ? 'no' : 'choose';
                        
                        if(self.data.classification.all) {
                            self.data.classification = {
                                all: true,
                                interests: [],
                                query: ''
                            }
                        }

                        self.data.hoursPerWeek = self.data.hoursPerWeek.toString();

                        self.$nextTick(() => {
                            self.loaded = true;
                            self.$emit('loaded');
                        })
                    }
                })
                .catch(error => {
                    if(error && error.response) {
                        if(error.response.status !== 400) {
                                console.log(JSON.stringify(error.response, null, 4))
                            }
                    } else { console.log(error); }
                });
        },

        update: function(data) {
            const self = this;
            Object.keys(data).forEach(key => {
                if(key === 'validation') { self.validation = data[key] }
                else { self.data[key] = data[key] }
            });
        },

        translate_choose_classification: function(key) {
            const self = this;
            return self.$t(`workerOffer.card.create.classification.choose.options.${key}`)
        },

        error: function(key) {
            try {
                return { error: this.validation[key].error }
            }catch (e) {
                console.log(`${this.$options.name}: validation error for ${key}`)
            }
        }
    },

    mounted() {
        const self = this; 
        self.getWorkerPreferences();
    }
}
</script>

