<template>
    <layout :classes="classes" 
            :routeName="routeName" 
            :navigation="navigation" 
            :sidebar="sidebar" 
            :scope="scope"
            :pace="{ activate: true, loaded: loaded.common && loaded.preferences }">
        
        <div id="sticky-heading-container" class="heading-container sticky"> 
            <div class="back-title-container">
                <BackButton />
                <h1 class="title">{{$t('pages.preferences.worker.title')}}</h1>
            </div>
        </div>
        
        <div id="with-sticky-header" class="row with-sticky-header top-row">
            <div class="left">
                <WorkerPreferencesCard :retirement_amount="retirement_amount" @loaded="loaded.preferences = true" />
            </div>
            <div class="right">
                <CommonPreferencesCard :worker="worker" :loaded="loaded.common" type="worker" />
            </div>
        </div>
    </layout>
</template>

<script>
import { bus } from '../../../../main';

import Layout from '../../../../components/Layout.vue';
import BackButton from '../../../../components/Navigation/BackButton.vue';
import WorkerPreferencesCard from '../../../../components/Cards/Offers/Worker/WorkerPreferencesCard.vue'
import CommonPreferencesCard from '../../../../components/Cards/Offers/CommonPreferencesCard.vue';

export default {
    name: 'Offer',

    components: {
        Layout,
        BackButton,
        WorkerPreferencesCard,
        CommonPreferencesCard
    },

    props: {
        id: {
            type: String,
            default: null
        },
        navigation: {
            type: String,
            default: 'simple'
        },
        sidebar: {
            type: Boolean,
            default: false
        },

        scope: {
            type: Object,
            default() {
                return {};
            }
        }
    },

    data() {
        return {
            retirement_amount: null,
            routeName: 'offer',
            classes: { dashboard: true, preferences: true },
            worker: {},

            loaded: {
                preferences: false,
                common: false,
            }
        }
    },

    methods: {
        get_profile: function() {
            const self = this;
            self.$axios
                .get('/worker')
                .then(response => {
                    if(response.status == 200){
                        self.worker = response.data;
                        self.retirement_amount = self.worker.retirement_amount;

                        self.$nextTick(() => {
                            self.loaded.common = true;
                            bus.$emit('reset_heading_container_margin', true);
                        })
                    }
                })
                .catch(error => {
                    if(error && error.response) {
                        if(error.response.status !== 400) {
                                console.log(JSON.stringify(error.response, null, 4))
                            }
                    } else { console.log(error); }
                });
        },
    },

    mounted() {
        const self = this;
        self.get_profile();
    }
};
</script>