<template>
    <div v-if="loaded" class="card card-profile animate__animated animate__fadeIn">
        <div class="card-heading border-bottom with-cta">
            <p class="title">{{$t(titleKey)}}</p>
        </div>

        <div class="card-body">
            <div class="question-wrapper">
                <div class="question distance-range">
                    <div class="label-container">
                        <p>{{$t('distanceRangeTitle')}}</p>
                        <FormError :data="validation.distanceRange" />
                    </div>

                    <the-mask :class="['input input-text', error('distanceRange')]" :mask="['# km', '## km', '### km']" v-model.number="data.distanceRange" />
                </div>

                <div class="question minimum-match-percentage">
                    <div class="label-container">
                        <p>{{$t('minimumMatchPercentageLevel')}}</p>
                        <p class="subtitle">{{$t('minimumMatchPercentageLevelSubtitle')}}</p>

                        <FormError :data="validation.minimumMatchPercentage" />
                    </div>

                    <multiselect    v-model="data.minimumMatchPercentage" 
                                    :options="minimumMatchPercentageOptions"
                                    :close-on-select="true"
                                    :multiple="false"
                                    :allowEmpty="false"
                                    :searchable="false"
                                    :class="error('minimumMatchPercentage')"
                                    
                                    placeholder="%"
                                    :select-label="$t('language.multiselect.label.select')"
                                    :selected-label="$t('language.multiselect.label.selected')"
                                    :deselect-label="$t('language.multiselect.label.deselect')"
                                    :custom-label="$utils._translateLabel"> </multiselect>
                </div>

                <div class="question">
                    <div class="label-container">
                        <p>{{$t('availableNowLabel')}}</p>
                    </div>

                    <div class="button-group collapsed">
                        <button :class="['cta cta-option', { active: data.availableNow }]" @click="data.availableNow = true">{{$t('language.yes')}}</button>
                        <button :class="['cta cta-option', { active: !data.availableNow }]" @click="data.availableNow = false">{{$t('language.no')}}</button>
                    </div>
                </div>

                <div v-if="data.availableNow === false" class="question next-time-available">
                    <div class="label-container">
                        <p>{{$t('nextTimeAvailable')}}</p>
                        <FormError :data="validation.nextTimeAvailable" />
                    </div>

                    <VueDatePicker  v-model="data.nextTimeAvailable" 
                                    :min-date="new Date()"
                                    :class="error('nextTimeAvailable')"
                                    :noHeader="true"
                                    :locale="{lang: currentLanguage}"
                                    color="#303030" />
                </div>

                <div class="question priority column">
                    <div class="label-container">
                        <p>{{$t(`profile.worker.card.priority.title`)}}</p>
                        <p class="subtitle">{{$t(`profile.worker.card.priority.subtitle`)}}</p>

                        <FormError :data="validation.priority"/>
                    </div>
                    
                    <multiselect    v-model="data.priority" 
                                    :options="priority_options"
                                    :close-on-select="true"
                                    :multiple="false"
                                    :allowEmpty="false"
                                    :searchable="false"
                                    :class="error('priority')"
                                    
                                    :placeholder="$t(`profile.worker.card.priority.input.placeholder`)"
                                    :select-label="$t('language.multiselect.label.select')"
                                    :selected-label="$t('language.multiselect.label.selected')"
                                    :deselect-label="$t('language.multiselect.label.deselect')"
                                    :custom-label="$utils._translate_priority"> 
            
                                    <template slot="noResult">{{$t('language.multiselect.label.no_result')}}</template>
                                    <template slot="noOptions">{{$t('language.multiselect.label.no_options')}}</template>
                    </multiselect>

                </div>
            </div>

            <div class="card-footer">
                <button class="cta cta-primary cta-slim" :class="{ loading: loading }" @click="updateProfile()">{{$t('cards.generic.submit.label')}}</button>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import FormError from '../../../components/Utils/FormError.vue'

export default {
    name: 'CommonPreferencesCard',

    components: {
        FormError
    },

    props: {
        type: String,
        loaded: Boolean,
        worker: {
            type: Object,
            default: null
        },
    },

    watch: {
        worker: {
            deep: true,
            handler() {
                const self = this;
                self.data = self.worker;
            }
        }
    },

    data() {
        return {

            data: {},
            minimumMatchPercentageOptions: [85, 75, 65],
            priority_options: ['hours', 'schedule', 'type', 'location', 'salary'],

            formValidated: false,
            loading: false,
            isLoadingDelete: false,

            titleKey: '',

            validation: {
                distanceRange: {
                    error: false,
                    key: 'errors.validation.fieldMandatory'
                },

                priority: {
                    error: false,
                    key: 'errors.validation.fieldMandatory'
                },

                minimumMatchPercentage: {
                    error: false,
                    key: 'errors.validation.fieldMandatory'
                },

                nextTimeAvailable: {
                    error: false,
                    key: 'errors.validation.fieldMandatory'
                }
            }
        }
    },

    computed: {
        ...mapGetters([
            'currentLanguage'
        ])
    },

    methods: {
        ...mapActions([
            'setToken'
        ]),

        validate_mandatory_fields: function() {
            const self = this;

            self.formValidated = true;
            Object.keys(self.validation).forEach(val => {
                self.validation[val].error = false

                if(val === 'nextTimeAvailable') {
                    if(!self.data.availableNow && !self.data.nextTimeAvailable)
                        self.validation.nextTimeAvailable.error = true
                }else if(!self.data[val])
                    self.validation[val].error = true;
            });
            
            Object.keys(self.validation).forEach(val => {
                if(self.validation[val].error)
                    self.formValidated = false
            });
        },

        updateProfile: function() {
            const self = this;
            
            self.validate_mandatory_fields()
            if(self.formValidated) {
                self.loading = true;
                if(self.data.availableNow) {
                    self.data.nextTimeAvailable = null;
                }

                const uri = self.type && self.type === 'volunteer' ? 'volunteer' : 'worker';
                if(uri === 'volunteer' || uri === 'worker') {   
                    self.$axios
                        .put(`/${uri}`, self.data)
                        .then(response => {
                            if(response.status == 200){
                                self.$toasted.success(
                                    self.$t('notifications.workProviderProfileUpdateSuccess'),
                                    { icon: 'circle-check' }
                                );

                                self.$nextTick(() => {
                                    self.loading = false;
                                    self.formValidated = false;
                                })
                            }
                        })
                        .catch(error => {
                            if(error && error.response) {
                                if(error.response.status !== 400) {
                                console.log(JSON.stringify(error.response, null, 4))
                            }
                            } else { console.log(error); }

                            self.loading = false;
                        });   
                }
            } else {
                self.$toasted.error(
                    self.$t('notifications.allFieldsAreMandatory'),
                    { icon: 'circle-exclamation' }
                );
            }
        },

        error: function(key) {
            try {
                return { error: this.validation[key].error }
            }catch (e) {
                console.log(`${this.$options.name}: validation error for ${key}`)
            }
        }
    },

    mounted() {
        const self = this; 

        self.titleKey = self.type && self.type === 'volunteer' ? 'profile.volunteer.card.title.default' : 'profile.worker.card.title.default'
        self.data = self.worker;
    }
}
</script>